import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import { STATUS, SET_POSSIBILITIES, STATUS_STUDY_ENDED } from '../../constants';
import Button from '../Button';
import Modal from '../Modal';

interface IStatus {
  id: number;
  name: string;
}
interface ISelectStatus {
  selected: number;
  onSelect: (status: number) => void;
}
interface IOption {
  status: number;
  label: string;
  onSelect?: () => void;
}
const StatusTag: React.FC<{ status: number }> = ({ status }) => (
  <span
    className={cn('flex h-[26px] w-[26px] flex-none items-center justify-center rounded-full bg-yellow', {
      '!bg-gray-light': [8, 9].includes(status),
      '!bg-green': [4, 7].includes(status),
    })}
  >
    {status}
  </span>
);
const Option: React.FC<IOption> = ({ status, label, onSelect }) => (
  <li
    className="flex min-h-[50px] cursor-pointer items-center px-3 text-left hover:bg-gray-light"
    onClick={onSelect || (() => null)}
    aria-hidden="true"
  >
    <StatusTag status={status} />
    <span className="ml-3">{label}</span>
  </li>
);

const SelectStatus: React.FC<ISelectStatus> = ({ selected, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(STATUS[selected]);
  const [options, setOptions] = useState<IStatus[]>([]);

  const handleToggleModal = () => setIsModalOpen((prevState) => !prevState);

  const handleToggleSelect = () => {
    if (selected === STATUS_STUDY_ENDED) return;
    setIsOpen((prevState) => !prevState);
  };

  const handleSelectOption = (statusId: number) => {
    if (statusId === STATUS_STUDY_ENDED) {
      handleToggleModal();
      return;
    }
    onSelect(statusId);
  };

  const handleSelectOptionStudyEnded = () => {
    onSelect(STATUS_STUDY_ENDED);
    handleToggleModal();
  };

  useEffect(() => {
    setCurrentStatus(STATUS[selected]);

    const possibleOptions: IStatus[] = STATUS.filter((item) => SET_POSSIBILITIES[selected].includes(item.id));

    setOptions(possibleOptions);
  }, [selected]);

  return (
    <>
      <Modal isOpen={isModalOpen}>
        <p className="mb-6">
          Attention, en choisissant ce statut, vous allez mettre fin à l&apos;étude pour ce patient.
        </p>
        <Button className="mb-3" label="Mettre fin à l'étude" onClick={handleSelectOptionStudyEnded} icon="tick" />
        <Button label="Annuler" onClick={handleToggleModal} icon="cross" />
      </Modal>
      <OutsideClickHandler
        onOutsideClick={() => {
          setIsOpen(false);
        }}
      >
        <div className={cn('h-[50px] w-full cursor-pointer')} onClick={handleToggleSelect} aria-hidden="true">
          <div
            className={cn('flex min-h-[50px] items-center rounded-md border border-gray-dark px-3 text-left', {
              'rounded-b-none': isOpen,
            })}
          >
            <StatusTag status={currentStatus.id} />
            <span className="ml-2 ">{currentStatus.name}</span>
          </div>
          {isOpen && (
            <ul
              className={cn('relative w-full border border-t-0 border-gray-dark bg-white', {
                'relative z-50': isOpen,
              })}
            >
              {options.map((item) => (
                <Option key={item.id} status={item.id} label={item.name} onSelect={() => handleSelectOption(item.id)} />
              ))}
            </ul>
          )}
        </div>
      </OutsideClickHandler>
    </>
  );
};

export default SelectStatus;
