import cn from 'classnames';
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import Checkbox from '../components/Checkbox';
import Loader from '../components/Loader';
import Cell from '../components/table/Cell';
import { PAGES, TABLE_THERAPISTS } from '../constants';
import AuthContext from '../store/AuthContext';
import DataContext from '../store/DataContext';

interface ILine {
  isActive: boolean;
  mail: string;
  id: number;
  name: string;
  patientsCount: number;
}
const Line: React.FC<ILine> = ({ isActive, mail, name, patientsCount, id }) => {
  const { handleUpdateTherapistStatus } = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(false);

  const onUpdateTherapist = async () => {
    setIsLoading(true);
    const nextStatus = isActive ? 1 : 2;

    await handleUpdateTherapistStatus(id, nextStatus).then(() => setIsLoading(false));
  };
  return (
    <tr
      className={cn('h-14 font-calibri odd:bg-white even:bg-gray-extra-light', {
        '!font-calibri-bold': !isActive,
      })}
    >
      <Cell size={TABLE_THERAPISTS.ACTIF.WIDTH}>
        {isLoading ? (
          <div className="flex items-center justify-center">
            <Loader className="h-8 w-8" />
          </div>
        ) : (
          <Checkbox id={mail} isChecked={isActive} onChange={onUpdateTherapist} />
        )}
      </Cell>
      <Cell size={TABLE_THERAPISTS.ID_GAIA.WIDTH}>{id}</Cell>
      <Cell alignLeft size={TABLE_THERAPISTS.NAME.WIDTH}>
        <span className="pl-3">{name}</span>
      </Cell>
      <Cell alignLeft size={TABLE_THERAPISTS.EMAIL.WIDTH}>
        <a className="pl-3 underline" href={`mailto:${mail}`}>
          {mail}
        </a>
      </Cell>
      <Cell size={TABLE_THERAPISTS.PATIENTS_COUNT.WIDTH}>{patientsCount}</Cell>
    </tr>
  );
};

const Therapists: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { isLogged } = useContext(AuthContext);
  const { therapists } = useContext(DataContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLogged) {
      navigate(PAGES.LOGIN.URL);
    } else {
      setIsLoading(false);
    }
  }, [isLogged, navigate]);

  if (isLoading || !therapists) return <p>Chargement...</p>;
  return (
    <div className="min-h-[calc(100vh_-_46px)]">
      <table className="w-full min-w-[1750px]">
        <thead>
          <tr>
            {Object.values(TABLE_THERAPISTS).map((item) => (
              <th
                key={item.ID}
                className="text-xl sticky top-0 z-40 border-r border-gray bg-white px-2 text-center font-calibri-bold after:absolute after:bottom-0 after:left-0 after:right-0 after:h-[1px] after:w-full after:bg-gray after:content-['']"
              >
                {item.NAME}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="z-40 border-b border-gray">
          {therapists.map((therapist) => (
            <Line
              key={therapist.id}
              name={`${therapist.lastName} ${therapist.firstName}`}
              id={therapist.id}
              isActive={therapist.active === 2}
              patientsCount={therapist.patientsCount}
              mail={therapist.email}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Therapists;
