import cn from 'classnames';
import React, { useState, useContext } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';

import { PAGES } from '../constants';
import useApi from '../hooks/useApi';
import AuthContext from '../store/AuthContext';

import { deleteSavedToken } from '../utils/sessionStorage';
import Button from './Button';
import Modal from './Modal';

interface IItem {
  label: string;
  to: string;
}
const Item: React.FC<IItem> = ({ label, to }) => {
  const location = useLocation();
  const isCurrent = location.pathname === to;
  return (
    <Link to={to} className="flex h-full cursor-pointer flex-col justify-center">
      <span className="text-xl px-6 font-calibri-bold uppercase text-white">{label}</span>
      <span
        className={cn('h-[9px] w-full', {
          'border border-gray bg-pink': isCurrent,
        })}
      />
    </Link>
  );
};

const Header: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isLogged, setIsLogged, setToken, token } = useContext(AuthContext);
  const navigation = useNavigate();
  const { logout } = useApi();

  const handleToggleModal = () => setIsModalOpen((prevState) => !prevState);
  const handleLogout = async () => {
    await logout(token);
    setIsLogged(false);
    setToken('');
    navigation(PAGES.LOGIN.URL);
    handleToggleModal();
    deleteSavedToken();
  };
  return (
    <>
      <Modal isOpen={isModalOpen}>
        <div className="flex flex-col items-center">
          <p className="text-xl mb-[42px] font-calibri-bold uppercase">Êtes-vous sur de vouloir vous déconnecter ?</p>
          <Button className="mb-[35px] uppercase" icon="tick" label="Oui" onClick={handleLogout} />
          <Button className="uppercase" icon="cross" label="Non" onClick={handleToggleModal} />
        </div>
      </Modal>
      <header className="fixed top-0 z-[-10] flex h-[46px] w-full bg-gray-dark">
        <div className="text-xl flex h-full w-[116px] items-center justify-center border border-gray bg-pink font-calibri-bold uppercase">
          <p className="text-white">Maevad</p>
        </div>
        <p className="text-xl ml-3 mr-6 flex h-full items-center font-calibri-bold text-white">{'>'}</p>
        {isLogged ? (
          <div className="flex w-full justify-between">
            <div className="flex">
              <Item to={PAGES.THERAPISTS.URL} label={PAGES.THERAPISTS.NAME} />
              <Item to={PAGES.PATIENTS.URL} label={PAGES.PATIENTS.NAME} />
            </div>

            <button type="button" className="mr-12" onClick={handleToggleModal}>
              <span>🔒</span>
              <span className="text-xl ml-2 font-calibri-bold uppercase text-white underline">Se déconnecter</span>
            </button>
          </div>
        ) : (
          <div>
            <Item to={PAGES.LOGIN.URL} label={PAGES.LOGIN.NAME} />
          </div>
        )}
      </header>
    </>
  );
};

export default Header;
