import React from 'react';
import formatDate from '../../utils/formatDate';

interface IDatesResume {
  startDate: string;
  endDate: string | null;
  label: string;
  isAvailable: boolean;
}

const DatesResume: React.FC<IDatesResume> = ({ startDate, endDate, label, isAvailable }) => (
  <div className="py-1">
    {isAvailable ? (
      <>
        {' '}
        <p>{`Du ${formatDate(startDate) || '...'}`}</p>
        <p>{`Au ${formatDate(endDate) || '...'}`}</p>
        <p className="font-calibri-bold">{label}</p>{' '}
      </>
    ) : (
      <p>_</p>
    )}
  </div>
);

export default DatesResume;
