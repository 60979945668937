import React from 'react';
import checkImage from '../assets/check.png';

interface ICheckbox {
  isChecked: boolean;
  onChange: (nextValue: boolean) => void;
  id: string;
}
const Checkbox: React.FC<ICheckbox> = ({ isChecked, onChange, id }) => (
  <div className="relative flex h-full w-full items-center justify-center">
    <label htmlFor={id} className="block h-6 w-6 cursor-pointer border border-gray-dark p-0.5">
      {isChecked && <img src={checkImage} alt="checkmark" />}
      <input
        className="absolute top-0 left-0 hidden h-full w-full"
        id={id}
        type="checkbox"
        checked={isChecked}
        onChange={(event) => onChange(event.currentTarget.checked)}
      />
    </label>
  </div>
);

export default Checkbox;
