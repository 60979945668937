import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Loader from '../components/Loader';
import TabletWithTooltip from '../components/TabletWithTooltip';
import Cell from '../components/table/Cell';
import DatesResume from '../components/table/DatesResume';
import SelectStatus from '../components/table/SelectStatus';
import { PAGES, TABLE_PATIENTS } from '../constants';
import AuthContext from '../store/AuthContext';
import DataContext from '../store/DataContext';

import { ITherapist, IPatient, ITest } from '../types/patients';
import formatDate from '../utils/formatDate';

const DetailsTest: React.FC<{ results?: ITest; label?: string }> = ({ results, label }) => {
  if (!results || !label) return null;
  return (
    <div className="flex w-full">
      <div className="flex w-1/6 items-center justify-end bg-orange-light py-2 pr-[15px] font-bold">{label}</div>
      <div className="w-1/6 bg-orange-extra-light py-2 text-center">
        {results.details.map((detail) => (
          <p key={`${detail.gameName}-${detail.playDate}-date`}>{formatDate(detail.playDate)}</p>
        ))}
      </div>
      <div className="w-4/6 bg-orange-extra-light py-2 pl-[15px]">
        {results.details.map((detail) => (
          <p key={`${detail.gameName}-${detail.playDate}-detail`}>
            <span className="font-calibri-bold">{detail.programName}</span> ::{' '}
            <span>
              {detail.gameName}
              {detail.variantName && ` - ${detail.variantName.toLowerCase()}`}
            </span>
          </p>
        ))}
      </div>
    </div>
  );
};

const DetailsProtocol: React.FC<{
  exercisesDates?: string[];
  label?: string;
}> = ({ exercisesDates, label }) => {
  if (!exercisesDates || exercisesDates.length === 0) return null;
  return (
    <div className="flex w-full">
      <div className="flex w-1/6 items-center justify-end bg-orange py-2 pr-[15px] font-calibri-bold">{label}</div>
      <div className="flex w-1/6 flex-col justify-between bg-orange-light py-2 text-center">
        <p>{exercisesDates[0]}</p>
        {exercisesDates.length !== 2 && (
          <p>
            {exercisesDates.length > 3 ? (
              <span className="font-calibri-bold">{`${exercisesDates.length} résultats`}</span>
            ) : (
              exercisesDates[1]
            )}
          </p>
        )}
        <p>{exercisesDates[exercisesDates.length - 1]}</p>
      </div>
      <div className="w-4/6 bg-orange-light py-2 pl-[15px]">
        <div className="flex flex-wrap ">
          {[...Array(30).keys()].map((_, index) => {
            if (exercisesDates[index]) {
              return <TabletWithTooltip key={`${index + 1}`} date={exercisesDates[index]} />;
            }
            return <div key={`${index + 1}`} className="m-[2px] h-[37px] w-[30px] rounded-md bg-white" />;
          })}
        </div>
      </div>
    </div>
  );
};

interface ILine {
  lineIndex: number;
  patient: IPatient;
  therapist: ITherapist;
}

const Line: React.FC<ILine> = ({ lineIndex, patient, therapist }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { handlePatientStatus } = useContext(DataContext);

  const onUpdatePatient = async (status: number) => {
    setIsLoading(true);
    await handlePatientStatus(therapist.id, patient.id, status).then(() => setIsLoading(false));
  };

  return (
    <tr className="h-14 font-calibri odd:bg-white even:bg-gray-extra-light">
      {lineIndex === 0 ? (
        <Cell size={TABLE_PATIENTS.THERAPIST_INFORMATION.WIDTH} alignLeft className="text-xl bg-gray-light">
          <div>
            <p className="font-bold">{`(${therapist.id}) ${therapist.name}`}</p>
            <a className="underline" href={`mailto:${therapist.mail}`}>
              {therapist.mail}
            </a>
          </div>
        </Cell>
      ) : (
        <Cell size={TABLE_PATIENTS.THERAPIST_INFORMATION.WIDTH} className="bg-gray-light" />
      )}
      <Cell size={TABLE_PATIENTS.ID_GAIA.WIDTH}>
        <span className="text-xl font-calibri-bold">{patient.id}</span>
      </Cell>
      <Cell size={TABLE_PATIENTS.ID_MAEVAD.WIDTH}>
        <span className="text-xl font-calibri-bold">{patient.maevadId}</span>
      </Cell>
      <Cell size={TABLE_PATIENTS.FIRST_CONNECTION.WIDTH}>
        {patient.firstConnection && <span className="text-xl">✔</span>}
      </Cell>
      <Cell size={TABLE_PATIENTS.STATUS.WIDTH}>
        {isLoading ? (
          <div className="flex items-center justify-center">
            <Loader className="h-8 w-8" />
          </div>
        ) : (
          <SelectStatus selected={patient.status} onSelect={onUpdatePatient} />
        )}
      </Cell>
      <Cell size={TABLE_PATIENTS.DETAILS.WIDTH}>
        {!!patient.results && (
          <span
            className="text-2xl mx-auto flex h-[30px] w-[30px] cursor-pointer items-center justify-center rounded-full bg-gray-dark text-white"
            onClick={() => setIsOpen((prevState) => !prevState)}
            aria-hidden="true"
          >
            {isOpen ? '-' : '+'}
          </span>
        )}
      </Cell>

      {isOpen && patient.results ? (
        <td colSpan={6}>
          <DetailsTest results={patient.results['test-1']} label="Test 1" />
          <DetailsProtocol
            exercisesDates={patient.results['protocol-1']?.details.map(
              (detail) => formatDate(detail.playDate) as string
            )}
            label="Protocole 1"
          />
          <DetailsTest results={patient.results['test-2']} label="Test 2" />
          <DetailsProtocol
            exercisesDates={patient.results['protocol-2']?.details.map(
              (detail) => formatDate(detail.playDate) as string
            )}
            label="Protocole 2"
          />
          <DetailsTest results={patient.results['test-3']} label="Test 3" />
          <DetailsTest results={patient.results['test-4']} label="Test FU" />
        </td>
      ) : (
        <>
          <Cell size={TABLE_PATIENTS.TEST_1.WIDTH}>
            {patient.results && patient.results['test-1'] ? (
              <DatesResume
                isAvailable={!!patient.results['test-1']}
                startDate={patient.results['test-1'].startDate}
                endDate={patient.results['test-1'].details[patient.results['test-1'].details.length - 1].playDate}
                label={`${patient.results['test-1'].details.length} épreuves`}
              />
            ) : (
              <p>_</p>
            )}
          </Cell>
          <Cell size={TABLE_PATIENTS.PROTOCOLE_1.WIDTH}>
            {patient.results && patient.results['protocol-1'] ? (
              <DatesResume
                isAvailable={!!patient.results['test-1']}
                startDate={patient.results['protocol-1'].startDate}
                endDate={patient.results['protocol-1'].endDate}
                label={`${patient.results['protocol-1'].details.length} épreuves`}
              />
            ) : (
              <p>_</p>
            )}
          </Cell>
          <Cell size={TABLE_PATIENTS.TEST_2.WIDTH}>
            {patient.results && patient.results['test-2'] ? (
              <DatesResume
                isAvailable={!!patient.results['test-1']}
                startDate={patient.results['test-2'].startDate}
                endDate={patient.results['test-2'].details[patient.results['test-2'].details.length - 1].playDate}
                label={`${patient.results['test-2'].details.length} épreuves`}
              />
            ) : (
              <p>_</p>
            )}
          </Cell>
          <Cell size={TABLE_PATIENTS.PROTOCOLE_2.WIDTH}>
            {patient.results && patient.results['protocol-2'] ? (
              <DatesResume
                isAvailable={!!patient.results['test-1']}
                startDate={patient.results['protocol-2'].startDate}
                endDate={patient.results['protocol-2'].endDate}
                label={`${patient.results['protocol-2'].details.length} épreuves`}
              />
            ) : (
              <p>_</p>
            )}
          </Cell>
          <Cell size={TABLE_PATIENTS.TEST_3.WIDTH}>
            {patient.results && patient.results['test-3'] ? (
              <DatesResume
                isAvailable={!!patient.results['test-1']}
                startDate={patient.results['test-3'].startDate}
                endDate={patient.results['test-3'].details[patient.results['test-3'].details.length - 1].playDate}
                label={`${patient.results['test-3'].details.length} épreuves`}
              />
            ) : (
              <p>_</p>
            )}
          </Cell>
          <Cell size={TABLE_PATIENTS.TEST_FU.WIDTH}>
            {patient.results && patient.results['test-4'] ? (
              <DatesResume
                isAvailable={!!patient.results['test-1']}
                startDate={patient.results['test-4'].startDate}
                endDate={patient.results['test-4'].details[patient.results['test-4'].details.length - 1].playDate}
                label={`${patient.results['test-4'].details.length} épreuves`}
              />
            ) : (
              <p>_</p>
            )}
          </Cell>
        </>
      )}
    </tr>
  );
};

const Patients: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { isLogged } = useContext(AuthContext);
  const { patients } = useContext(DataContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLogged) {
      navigate(PAGES.LOGIN.URL);
    } else {
      setIsLoading(false);
    }
  }, [isLogged, navigate]);

  return isLoading || !patients ? (
    <p>Chargement...</p>
  ) : (
    <div className="min-h-[calc(100vh_-_46px)]">
      <table className="w-full min-w-[1750px]">
        <thead>
          <tr>
            {Object.values(TABLE_PATIENTS).map((item) => (
              <th
                key={item.ID}
                className="text-xl sticky top-0 z-40 border-r border-gray border-gray bg-white px-2 text-center font-calibri-bold after:absolute after:-bottom-[1px] after:left-0 after:right-0 after:h-[1px] after:w-full after:bg-gray after:content-['']"
              >
                {item.NAME}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="z-40 border-b border-gray">
          {patients &&
            patients.map((therapist) =>
              therapist.patients.map((patient, index) => (
                <Line
                  key={patient.id}
                  lineIndex={index}
                  patient={patient}
                  therapist={{
                    id: therapist.id,
                    mail: therapist.email,
                    name: `${therapist.firstName} ${therapist.lastName}`,
                    isActive: true,
                  }}
                />
              ))
            )}
        </tbody>
      </table>
    </div>
  );
};

export default Patients;
