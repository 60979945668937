import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import Header from './components/Header';
import { PAGES } from './constants';
import Login from './pages/Login';
import Patients from './pages/Patients';
import Therapists from './pages/Therapists';
import { AuthContextProvider } from './store/AuthContext';
import { DataContextProvider } from './store/DataContext';

const App: React.FC = () => (
  <BrowserRouter>
    <AuthContextProvider>
      <DataContextProvider>
        <>
          <Header />
          <div className="mt-[46px]">
            <Routes>
              <Route path={PAGES.LOGIN.URL} element={<Login />} />
              <Route path={PAGES.THERAPISTS.URL} element={<Therapists />} />
              <Route path={PAGES.PATIENTS.URL} element={<Patients />} />
              <Route path="*" element={<Navigate to={PAGES.LOGIN.URL} />} />
            </Routes>
          </div>
        </>
      </DataContextProvider>
    </AuthContextProvider>
  </BrowserRouter>
);

export default App;
