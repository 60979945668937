import axios from 'axios';
import { IPatientList } from '../types/patients';
import { ITherapist } from '../types/therapists';

const API = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    appName: 'maevad',
    appVersion: '1.0.0',
    'Content-Type': 'application/json',
  },
});

interface IUseApi {
  login: (username: string, password: string) => Promise<{ ticket: string } | false>;
  logout: (token: string) => Promise<number | false>;
  getPatients: (token: string) => Promise<IPatientList | null>;
  getTherapists: (token: string) => Promise<ITherapist[] | null>;
  updateUserStatus: (params: { token: string; userId: number; status: number }) => Promise<boolean>;
}

const useApi = (): IUseApi => {
  const login = async (username: string, password: string): Promise<{ ticket: string } | false> => {
    try {
      const body = JSON.stringify({
        login: username,
        password,
      });
      const response = await API.post('/users/auth', body);
      return response.data;
    } catch (e) {
      return false;
    }
  };

  const logout = async (token: string): Promise<number | false> => {
    try {
      const response = await API.post(
        '/users/logout',
        {},
        {
          headers: {
            ticket: token,
          },
        }
      );
      return response.status;
    } catch (e) {
      return false;
    }
  };

  const getPatients = async (token: string): Promise<IPatientList | null> => {
    try {
      const response = await API.get('/patients', {
        headers: {
          ticket: token,
        },
      });
      return response.data;
    } catch (e) {
      return null;
    }
  };

  const getTherapists = async (token: string): Promise<ITherapist[] | null> => {
    try {
      const response = await API.get('/therapists', {
        headers: {
          ticket: token,
        },
      });
      return response.data;
    } catch (e) {
      return null;
    }
  };

  const updateUserStatus = async ({
    token,
    userId,
    status,
  }: {
    token: string;
    userId: number;
    status: number;
  }): Promise<boolean> => {
    try {
      const body = { status };
      const response = await API.put(`/users/${userId}/status`, body, {
        headers: {
          ticket: token,
        },
      });
      return response.status === 200;
    } catch (e) {
      console.error('error: ', e);
      return false;
    }
  };

  return {
    login,
    logout,
    getPatients,
    getTherapists,
    updateUserStatus,
  };
};

export default useApi;
