import React, { ReactNode, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '../components/Button';
import InputText from '../components/InputText';
import { PAGES } from '../constants';
import useApi from '../hooks/useApi';
import AuthContext from '../store/AuthContext';

import { saveToken } from '../utils/sessionStorage';

const FormLine: React.FC<{ children: ReactNode }> = ({ children }) => <div className="mb-16 w-full">{children}</div>;

const Login: React.FC = () => {
  const [inputValues, setInputValues] = useState({
    username: '',
    password: '',
  });
  const [errorMessage, setErrorMessage] = useState<string>();
  const { setIsLogged, setToken, isCheckingAuth } = useContext(AuthContext);
  const navigation = useNavigate();
  const { login } = useApi();

  const handleLogin = async () => {
    if (Object.values(inputValues).some((value) => value.length === 0)) return;

    const response = await login(inputValues.username, inputValues.password);
    if (!response) {
      setErrorMessage('Le couple Identifiant / Mot de passe ne correspond a aucun compte. Merci de réessayer.');
      return;
    }
    saveToken(response.ticket);
    setIsLogged(true);
    setToken(response.ticket);
    navigation(PAGES.THERAPISTS.URL);
  };

  if (isCheckingAuth) return null;
  return (
    <div className="flex min-h-[calc(100vh_-_46px)] w-full items-center justify-center bg-gray-light">
      <div className="flex w-full max-w-3xl flex-col items-center justify-center border border-gray-dark bg-white px-[27px] py-[39px]">
        {errorMessage && <p className="mb-6 text-center text-xl font-bold text-red">{errorMessage}</p>}
        <FormLine>
          <InputText
            label="Identifiant"
            value={inputValues.username}
            onChangeValue={(nextValue) => setInputValues({ ...inputValues, username: nextValue })}
            id="username"
          />
        </FormLine>
        <FormLine>
          <InputText
            label="Mot de passe"
            type="password"
            value={inputValues.password}
            onChangeValue={(nextValue) => setInputValues({ ...inputValues, password: nextValue })}
            id="password"
          />
        </FormLine>
        <Button label="Se connecter" icon="tick" onClick={handleLogin} />
      </div>
    </div>
  );
};

export default Login;
