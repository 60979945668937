import React, { ReactNode } from 'react';

interface IModal {
  children: ReactNode;
  isOpen: boolean;
}

const Modal: React.FC<IModal> = ({ children, isOpen }) => {
  if (!isOpen) return null;
  return (
    <div className="fixed top-0 left-0 right-0 z-50 flex h-screen w-full items-center justify-center bg-gray">
      <div className="w-full max-w-2xl border border-gray-dark bg-white p-[26px]">{children}</div>
    </div>
  );
};

export default Modal;
