import cn from 'classnames';
import React, { useState } from 'react';

interface ITabletWithTooltip {
  date: string;
}

const TabletWithTooltip: React.FC<ITabletWithTooltip> = ({ date }) => {
  const [isShowed, setIsShowed] = useState(false);
  return (
    <div className="relative" onMouseEnter={() => setIsShowed(true)} onMouseLeave={() => setIsShowed(false)}>
      {isShowed && (
        <span className="absolute top-[25px] right-[15px] z-50 flex cursor-default items-center justify-center bg-green-dark px-2 font-calibri-bold text-white">
          {date}
        </span>
      )}
      <div
        className={cn('m-[2px] h-[37px] w-[30px] rounded-md bg-green', {
          '!bg-green-dark': isShowed,
        })}
      />
    </div>
  );
};

export default TabletWithTooltip;
