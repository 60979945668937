import cloneDeep from 'clone-deep';
import React, { useContext, createContext, useState, useEffect, ReactElement, useMemo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { PAGES, STATUS_STUDY_ENDED } from '../constants';
import useApi from '../hooks/useApi';

import { IPatientList } from '../types/patients';
import { ITherapist } from '../types/therapists';
import AuthContext from './AuthContext';

interface IDataContext {
  patients: IPatientList | null;
  therapists: ITherapist[] | null;
  handleUpdateTherapistStatus: (therapistId: number, nextStatus: number) => Promise<void>;
  handlePatientStatus: (patientId: number, therapistId: number, newStatus: number) => Promise<void>;
}

const DataContext = createContext<IDataContext>({} as IDataContext);

export const DataContextProvider: React.FC<{ children: ReactElement }> = ({ children }) => {
  const { token, isLogged } = useContext(AuthContext);
  const [patients, setPatients] = useState<IPatientList | null>(null);
  const [therapists, setTherapists] = useState<ITherapist[] | null>(null);
  const location = useLocation();
  const { getPatients, getTherapists, updateUserStatus } = useApi();

  const handleUpdateTherapistStatus = useCallback(
    async (therapistId: number, newStatus: number) => {
      if (!therapists) return;
      const response = await updateUserStatus({
        token,
        userId: therapistId,
        status: newStatus,
      });
      if (!response) return;
      const newTherapists = cloneDeep(therapists).map((therapist) => {
        if (therapist.id === therapistId) {
          therapist.active = newStatus;
        }
        return therapist;
      });
      setTherapists(newTherapists);
    },
    [therapists, token, updateUserStatus]
  );

  const handleUpdateTherapistPatientsCount = useCallback(
    (therapistId: number, newPatientCount: number) => {
      if (!therapists) return;
      const newTherapists = cloneDeep(therapists)?.map((therapist) => {
        if (therapist.id === therapistId) {
          therapist.patientsCount = newPatientCount;
        }
        return therapist;
      });
      setTherapists(newTherapists);
    },
    [therapists]
  );

  const handlePatientStatus = useCallback(
    async (therapistId: number, patientId: number, newStatus: number) => {
      if (!patients) return;
      const response = await updateUserStatus({
        token,
        userId: patientId,
        status: newStatus,
      });
      if (!response) return;
      const newPatients = cloneDeep(patients).map((therapist) => {
        if (therapist.id === therapistId) {
          // patient has ended study: removed from patient list + update patient count in therapist list
          if (newStatus === STATUS_STUDY_ENDED) {
            const newPatientList = therapist.patients.filter((patient) => patient.id !== patientId);
            therapist.patients = newPatientList;
            handleUpdateTherapistPatientsCount(therapistId, newPatientList.length);
          } else {
            therapist.patients.forEach((patient) => {
              if (patient.id === patientId) patient.status = newStatus;
            });
          }
        }
        return therapist;
      });
      setPatients(newPatients);
    },
    [handleUpdateTherapistPatientsCount, patients, token, updateUserStatus]
  );

  useEffect(() => {
    if (!isLogged) return;
    if (location.pathname === PAGES.THERAPISTS.URL && !therapists) {
      const fetchTherapists = async () => {
        setTherapists(await getTherapists(token));
      };
      fetchTherapists().catch((error) => console.error(error));
    }
    if (location.pathname === PAGES.PATIENTS.URL && !patients) {
      const fetchPatients = async () => {
        setPatients(await getPatients(token));
      };
      fetchPatients().catch((error) => console.error(error));
    }
  }, [isLogged, therapists, patients, location.pathname, getPatients, getTherapists, token]);

  const data = useMemo(
    () => ({
      patients,
      therapists,
      handleUpdateTherapistStatus,
      handlePatientStatus,
    }),
    [handlePatientStatus, handleUpdateTherapistStatus, patients, therapists]
  );
  return <DataContext.Provider value={data}>{children}</DataContext.Provider>;
};

export default DataContext;
