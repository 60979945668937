export const PAGES = {
  LOGIN: {
    URL: '/',
    NAME: 'Connexion',
  },
  PATIENTS: {
    URL: '/patients',
    NAME: 'Liste des patients',
  },
  THERAPISTS: {
    URL: '/therapists',
    NAME: 'Liste des thérapeutes',
  },
};

export const STATUS = [
  {
    id: 0,
    name: "En attente d'envoie de la platforme",
  },
  {
    id: 1,
    name: "En attente d'installation de la platforme",
  },
  {
    id: 2,
    name: 'En attente de la fin du test 1',
  },
  {
    id: 3,
    name: 'Test 1 terminé, en attente pour débuter Protocole 1',
  },
  {
    id: 4,
    name: 'Protocole 1 activé',
  },
  {
    id: 5,
    name: 'En attente de la fin du test 2 pour débuter Protocole 2',
  },
  {
    id: 6,
    name: 'Test 2 terminé, en attent pour débuter Protocole 2',
  },
  {
    id: 7,
    name: 'Protocole 2 activé',
  },
  {
    id: 8,
    name: 'Protocole 2 terminé',
  },
  {
    id: 9,
    name: 'Etude terminée',
  },
];
export const STATUS_STUDY_ENDED = 9;
export const SET_POSSIBILITIES: {
  [key: string]: number[];
} = {
  '0': [9],
  '1': [9],
  '2': [2, 3, 9],
  '3': [2, 3, 9],
  '4': [9],
  '5': [5, 6, 9],
  '6': [5, 6, 9],
  '7': [9],
  '8': [9],
  '9': [],
};

export const TABLE_THERAPISTS = {
  ACTIF: {
    ID: 0,
    WIDTH: 5,
    NAME: 'Actif',
  },
  ID_GAIA: {
    ID: 1,
    WIDTH: 10,
    NAME: 'Id thérapeute GAIA',
  },
  NAME: {
    ID: 2,
    WIDTH: 38,
    NAME: 'Nom prénom du thérapeute',
  },
  EMAIL: {
    ID: 3,
    WIDTH: 37,
    NAME: 'Email du thérapeute',
  },
  PATIENTS_COUNT: {
    ID: 4,
    WIDTH: 10,
    NAME: 'Nb de patient inscrit',
  },
};
export const TABLE_PATIENTS = {
  THERAPIST_INFORMATION: {
    ID: 0,
    NAME: 'Informations thérapeute',
    WIDTH: 17,
  },
  ID_GAIA: {
    ID: 1,
    NAME: 'ID patient GAIA',
    WIDTH: 5,
  },
  ID_MAEVAD: {
    ID: 2,
    NAME: 'ID patient étude',
    WIDTH: 5,
  },
  FIRST_CONNECTION: {
    ID: 3,
    NAME: 'Premier lancement',
    WIDTH: 3,
  },
  STATUS: {
    ID: 4,
    NAME: 'Status',
    WIDTH: 18,
  },
  DETAILS: {
    ID: 5,
    NAME: 'Détails',
    WIDTH: 4,
  },
  TEST_1: {
    ID: 6,
    NAME: 'Test 1',
    WIDTH: 8,
  },
  PROTOCOLE_1: {
    ID: 7,
    WIDTH: 8,
    NAME: 'Protocole 1',
  },
  TEST_2: {
    ID: 8,
    NAME: 'Test 2',
    WIDTH: 8,
  },
  PROTOCOLE_2: {
    ID: 9,
    NAME: 'Protocole 2',
    WIDTH: 8,
  },
  TEST_3: {
    ID: 10,
    NAME: 'Test 3',
    WIDTH: 8,
  },
  TEST_FU: {
    ID: 11,
    NAME: 'Test FU',
    WIDTH: 8,
  },
};
