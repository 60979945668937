import React, { useState, createContext, useEffect, ReactNode, Dispatch, SetStateAction, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { PAGES } from '../constants';
import { retrieveToken } from '../utils/sessionStorage';

interface IAuthContext {
  isLogged: boolean;
  setIsLogged: Dispatch<SetStateAction<boolean>>;
  token: string;
  setToken: Dispatch<SetStateAction<string>>;
  isCheckingAuth: boolean;
}

const AuthContext = createContext<IAuthContext>({
  isLogged: false,
  setIsLogged: () => null,
  token: '',
  setToken: () => null,
  isCheckingAuth: true,
});

export const AuthContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [token, setToken] = useState('');
  const [isLogged, setIsLogged] = useState(false);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isCheckingAuth) return;
    const tokenInSession = retrieveToken();
    if (tokenInSession) {
      setToken(tokenInSession);
      setIsLogged(true);
      navigate(PAGES.THERAPISTS.URL);
    }
    setIsCheckingAuth(false);
  }, [navigate, isCheckingAuth]);

  const auth = useMemo(
    () => ({
      isLogged,
      setIsLogged,
      token,
      setToken,
      isCheckingAuth,
    }),
    [isCheckingAuth, isLogged, token]
  );
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export default AuthContext;
